<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item["@id"]}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />

    <div v-if="item" class="table-partenaire-show">
      <v-simple-table>
        <template slot="default">
          <tbody>
            <tr>
              <!-- <tb>
                <strong>{{ $t("fileImage") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["fileImage"] }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("nom") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["nom"] }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("categorie") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["categorie"].nom }}
                </v-col>
              </tb>

              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["evenement"].nom }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("siteWeb") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["siteWeb"] }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("facebook") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["facebook"] }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("image") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["image"] }}
                </v-col>
              </tb>

              <!-- <tb>
                <strong>{{ $t("description") }}</strong>
              </tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  <span v-html="item['description']"></span>
                </v-col>
              </tb>
              
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Loading from "../../components/Loading";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";

const servicePrefix = "Partenaire";

export default {
  name: "PartenaireShow",
  servicePrefix,
  components: {
    Loading,
    Toolbar,
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields("partenaire", {
      isLoading: "isLoading",
    }),
    ...mapGetters("partenaire", ["find"]),
  },
  methods: {
    ...mapActions("partenaire", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load",
    }),
  },
};
</script>
